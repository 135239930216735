<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.stop.prevent="handleLogin">
                  <h1>MCST 4560</h1>
                  <p class="text-muted">
                    Please sign in to your account below.
                  </p>
                  <form-group
                    :validator="$v.form.username"
                    label="Email"
                    :use-label="false"
                    :use-horizontal="false"
                  >
                    <b-input-group class="mb-3">
                      <b-input-group-prepend
                        ><b-input-group-text
                          ><i class="icon-user"></i></b-input-group-text
                      ></b-input-group-prepend>
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Email or Account#"
                        autocomplete="off"
                        v-model="form.username"
                        :v="$v.form.username"
                      />
                    </b-input-group>
                  </form-group>
                  <form-group
                    :validator="$v.form.password"
                    label="Password"
                    :use-label="false"
                    :use-horizontal="false"
                  >
                    <b-input-group class="mb-4">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-lock"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        autocomplete="off"
                        v-model="form.password"
                        :v="$v.form.password"
                      />
                    </b-input-group>
                  </form-group>
                  <b-row>
                    <b-col cols="12">
                      <b-button type="submit" variant="primary" class="px-4">
                        Login
                      </b-button>
                      <b-button type="reset" variant="secondary" class="float-right px-4">
                        Clear
                      </b-button>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      If you forgot password, please contact us@912345678
                      <!-- <router-link
                        :to="{ name: 'ForgotPassword' }"
                        class="px-0"
                      >
                        Forgot password ?
                      </router-link> -->
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data: () => ({
    state: "login",
    form: {
      username: null,
      password: null,
    },
  }),
  validations: {
    form: {
      username: { required },
      password: { required },
    },
  },
  methods: {
    handleLogin() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let loader = self.$loading.show();
      self.$store.dispatch("auth/signin", self.form).then((response) => {
        if (response.error) {
          self.$message.error({
            zIndex: 10000,
            message: response.message,
          });
        } else {
          self.$message.success({
            zIndex: 10000,
            message: response.message,
          });

          self.$router.push({ name: "Dashboard" });
        }
        loader.hide();
      });
    },
  },
};
</script>
